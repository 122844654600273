import React, { useEffect, useState } from 'react';

interface ContentItem {
  type: 'text' | 'link';
  value?: string;
  text?: string;
  url?: string;
}

interface ModalConfig {
  title: string;
  content: ContentItem[]; 
}

interface WelcomeModalProps {
  showModal: boolean;
  closeModal: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ showModal, closeModal }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

  useEffect(() => {
    fetch('https://pulsar-inc.fr:3080/api/welcome-modal')
      .then(response => response.json())
      .then(data => setModalConfig(data))
      .catch(error => console.error('Erreur lors du chargement de la configuration du modal:', error));
  }, []);

  if (!showModal || !modalConfig) return null;

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.currentTarget === event.target) {
      closeModal();
    }
  };

  const renderContent = () => {
    return modalConfig.content.map((item, index) => {
      if (item.type === 'text') {
        return <p key={index}>{item.value}</p>;
      }
      if (item.type === 'link') {
        return (
          <p key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.text}
            </a>
            {item.value && <span> - {item.value}</span>}
          </p>
        );
      }
      return null;
    });
  };

  return (
    <React.Fragment>
      <div className="modalBackdrop" onClick={handleBackdropClick}></div>
      <div className="WelcomeModal" onClick={(e) => e.stopPropagation()}>
        <h2>{modalConfig.title}</h2>
        {renderContent()}
        <button onClick={closeModal}>Fermer</button>
      </div>
    </React.Fragment>
  );
};

export default WelcomeModal;