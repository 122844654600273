import React from 'react';
import { Evenement } from '../data/EvenementsData';
import MyImage from './reactLazyLoading';
interface EvenementItemProps {
  evenement: Evenement;
  isPast?: boolean;
  onOpenModal?: (evenement: Evenement) => void;
}

const EvenementItem: React.FC<EvenementItemProps> = ({ evenement, isPast, onOpenModal }) => {
  const handleOpenModal = () => {
    if (isPast && onOpenModal) {
      onOpenModal(evenement);
    }
  };

  return (
    <div
      className={`EvenementItem ${isPast ? 'clickable' : ''}`}
      onClick={handleOpenModal}
    >
      <MyImage
        src={evenement.photos[0]} // La première image de l'événement
        height="100%"
        width="100%"
        className="EvenementItem-photo"
      />

      <div className="EvenementContent">
        <h2>{evenement.NomEven}</h2>
        <p>{new Date(evenement.dateProp).toLocaleDateString()}</p>
        <p>{new Date(evenement.dateProp).toLocaleTimeString()}</p>
        <p><a href={evenement.googleMapsLink} target="_blank" rel="noopener noreferrer">{evenement.Lieu}</a></p>
        <p>Artiste: {evenement.Artiste}</p>
      </div>
      {isPast && (
        <div
          className="three-dots"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenModal();
          }}
        >
          <span className='dot1'>&#x2022;</span>
          <span className='dot2'>&#x2022;</span>
          <span className='dot3'>&#x2022;</span>
        </div>
      )}
    </div>
  );
};

export default EvenementItem;