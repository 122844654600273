import { motion } from 'framer-motion';
import { transition1 } from "../transition"

type TitreSlideProps = {
  GrosTitre: string;
  PetitTitre: string;
};

const TitreSlide: React.FC<TitreSlideProps> = ({ GrosTitre, PetitTitre }) => {

  return (
    <div className="Intro-section">
      <div className="bar-intro"></div>
      <motion.h2
        initial={{ opacity: 0 }}
        transition={transition1}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="title-slide">{GrosTitre}
      </motion.h2 >
      <motion.p
        initial={{ opacity: 0, translateX: "-200px" }}
        transition={transition1}
        whileInView={{ opacity: 1, translateX: "0px" }}
        viewport={{ once: true }}
        className="Description">{PetitTitre}</motion.p>
    </div>
  )
}

export default TitreSlide 