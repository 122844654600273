import React from "react";
import { NavLink } from "react-router-dom";

import { FaInstagram } from "react-icons/fa";

const Footer: React.FC = () => {
    // Obtenir la date actuelle
    const currentDate = new Date();
    // Options pour le format du mois
    const options: Intl.DateTimeFormatOptions = {
        month: "long",
        year: "numeric",
        day: "numeric",
    };
    // Formater la date
    const formattedDate = currentDate.toLocaleDateString("fr-FR", options);

    return (
        <footer>
            <div className="VSlide4-menu-info">
                <div>
                    <div><p>{formattedDate}</p></div>
                    <div>
                        <a href="mailto:contact@pulsar-inc.fr"><p>contact@pulsar-inc.fr</p></a>
                        {/* <p> 2 RUE DU GUIZAY, 42100 SAINT-ETIENNE</p> */}
                    </div>
                </div>
                <div className="VSlide4-menu-social-list">
                    <a href="https://www.instagram.com/pulsar_inc/" target="_blank">
                        <FaInstagram style={{ height: "2em", width: "2em" }} />
                    </a>
                </div>
                <p>
                    <NavLink to='/cgv'>MENTIONS LEGALES |</NavLink>
                    <NavLink to='/cgv'> CGV |</NavLink>
                    <NavLink to='/cgv'> POLITIQUE DE CONFIDENTIALITE</NavLink>
                </p>
            </div>
            <div className="VSlide4-menu-info">
                <p>© PULSAR inc. {currentDate.getFullYear()} | Tous droits réservés |</p>
                <p>SITE BY V.MARCONNET</p>
            </div>
        </footer>
    );
};

export default Footer;
