import { NavLink } from 'react-router-dom';
import { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { motion } from 'framer-motion';

function MenuBurger() {
  const [showLinks, setShowLinks] = useState(false);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  }

  const closeMenu = () => {
    setShowLinks(false);
  }
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    year: "numeric",
    day: "numeric",
  };

  const formattedDate = currentDate.toLocaleDateString("fr-FR", options);
  const setDarkMode = () => {
    document.querySelector("body")?.setAttribute('data-theme', 'light')
  }
  const setLightMode = () => {
    document.querySelector("body")?.setAttribute('data-theme', 'dark')
  }
  const toggletheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) setDarkMode();
    else setLightMode();
  };

  const liVariants = {
    visible: (index: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: index * 0.1
      }
    }),
    hidden: { opacity: 0, x: 100 }
  };


  const menuItems = [
    { label: "Accueil", path: "/" },
    { label: "Événements", path: "/Evenements" },
    { label: "Mitchy", path: "/Mitchy" },
    { label: "Boutique", path: "/Boutique" },
    { label: "Contact", path: "/Contact" },
  ];


  return (
    <nav className={`navbar ${showLinks ? 'show-nav' : 'hide-nav'}`}>

      <ul className="navbar_links">
        <div className='navbar_links_left'>
          {menuItems.map((item, index) => (
            <motion.li
              className='navbar_item'
              initial="hidden"
              whileInView="visible"
              variants={liVariants}

              custom={index}
              key={index}
            >
              <NavLink to={item.path} onClick={closeMenu}>
                <span>{`0${index + 1}`}</span> {item.label}
              </NavLink>
            </motion.li>
          ))}
        </div>

        <div className='navbar_links_right'>
          <motion.div
            initial={{ opacity: 1, y: 0 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
                delay: 0.1
              }
            }}

            className='navbar_links_right_left'>
            <p>© PULSAR inc. {currentDate.getFullYear()} | SITE BY V.MARCONNET</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 1, y: 0 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
                delay: 0.2
              }
            }}
            className='navbar_links_right_mid'>
            <div className="Menu-social-list">
              <a href="https://www.instagram.com/pulsar_inc/" target="_blank">
                <FaInstagram />
              </a>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 1, y: 0 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.5,
                delay: 0.3
              }
            }}
            className="navbar_links_right_right">
            <input
              className='dark_mode_input'
              type="checkbox"
              id='darkmode-toggle'
              onChange={toggletheme}
            />
            {/* <label className='dark_mode_label' htmlFor="darkmode-toggle">
              <span >☀︎</span>
              <span >☽</span>
            </label> */}
            <motion.div className='navbar_links_right-lien'>
              <NavLink className='' to='/cgv' onClick={closeMenu}>MENTIONS LEGALES |</NavLink>
              <NavLink className='' to='/cgv' onClick={closeMenu}> CGV |</NavLink>
              <NavLink className='' to='/cgv' onClick={closeMenu}>POLITIQUE DE CONFIDENTIALITE</NavLink>
            </motion.div>
          </motion.div>


        </div>
      </ul>
      <button className="navbar_burger" onClick={handleShowLinks}>
        <span className="burger-bar"></span>
      </button>
    </nav>
  );
}
export default MenuBurger;
