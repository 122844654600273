import { useParams } from 'react-router-dom';
import { produitsData } from '../../../data/produitsData';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProduitCarrousel = () => {
    const { productId } = useParams();
    const produit = produitsData.find((produit) => produit.to === productId);

    return produit ? (
        <section className="produit-images">
            <Slider
                className='center'
                centerPadding='100px'
                dots={true}
                dotsClass='slick-dots-test slick-thumb'
                arrows={false}

                customPaging={(i) => (
                    <a key={i}>
                        <img
                            src={produit.ImagesSupplementaires[i]}
                            alt={produit.AltText}
                            className="produit-supplementaire-image-puce"
                        />
                    </a>
                )}

                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                responsive={
                    [{
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                        }
                    }]
                }>

                {produit.ImagesSupplementaires.map((content, index) => (
                    <div className='produit-supplementaire-image-container' key={index}>
                        {content.endsWith('.mp4') ? (
                            <video
                                className='produit-supplementaire-video'
                                src={content}
                                loop
                                muted
                                autoPlay
                                playsInline
                            />
                        ) : (
                            <img
                                src={content}
                                alt={produit.AltText}
                                className="produit-supplementaire-image"
                            />
                        )}
                    </div>
                ))}
            </Slider>
        </section>
    ) : null;
};

export default ProduitCarrousel;