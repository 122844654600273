import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const EasterEgg: React.FC = () => {
  const [showEasterEgg, setShowEasterEgg] = useState(false);
  const [isPC, setIsPC] = useState(true); // État pour détecter si c'est un PC

  // Détecte si l'utilisateur est sur PC (largeur d'écran)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsPC(false);  // Si la largeur de l'écran est inférieure à 768px, c'est probablement un mobile ou une tablette
      } else {
        setIsPC(true);   // Sinon, c'est probablement un PC
      }
    };

    handleResize(); // Appel initial lors du chargement du composant
    window.addEventListener('resize', handleResize); // Écoute le redimensionnement de la fenêtre

    return () => {
      window.removeEventListener('resize', handleResize); // Nettoyage
    };
  }, []);

  const handleEasterEgg = () => {
    setShowEasterEgg(true);
  };

  // Si l'utilisateur n'est pas sur PC, ne pas rendre le composant
  if (!isPC) return null;

  return (
    <div className="easter-egg-container">
      {/* Zone cachée qui déclenche l'Easter Egg */}
      <div 
        className="hidden-area" 
        onClick={handleEasterEgg} 
        style={{ width: '100px', height: '100px', position: 'absolute', bottom: '0', left: '0' }}>
      </div>

      {showEasterEgg && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5, rotate: -180 }}
          animate={{ opacity: 1, scale: 1, rotate: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          style={{ 
            position: 'fixed', 
            bottom: '0', 
            left: '0', 
            zIndex: 1000 
          }}
        >
          <motion.a 
            href="https://maxence-jacquot.itch.io/super-mitchys-adventure" 
            target="_blank" 
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1, rotate: 10 }}  
            whileTap={{ scale: 0.9, rotate: -10 }} 
          >
            <motion.img 
              src="images/MitchyEasterEgg.png" 
              alt="Easter Egg" 
              style={{ width: '100px', height: '100px' }}
              animate={{
                scale: [1, 1.05, 1],
              }}
              transition={{
                duration: 1,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </motion.a>
        </motion.div>
      )}
    </div>
  );
};

export default EasterEgg;