import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleUp } from 'react-icons/fa';

const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Définir un seuil de défilement à partir duquel le bouton sera visible
    const scrollThreshold = 300;

    // Gérer le défilement de la page
    const handleScroll = () => {
        if (window.pageYOffset > scrollThreshold) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        console.log('Scroll Position:', window.pageYOffset);
    };

    // Écouter les événements de défilement de la page
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fonction pour faire défiler la page vers le haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Pour une animation de défilement fluide
        });
    };

    return (
        <div className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
            onClick={scrollToTop}
        >
            <FaArrowAltCircleUp />
        </div>
    );
};

export default ScrollToTopButton;
