import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

import { NavLink } from 'react-router-dom';

// Donnée
import { produitsData } from '../../data/produitsData';

import { motion, AnimatePresence } from 'framer-motion';
import { IoIosArrowDown } from "react-icons/io";

import ProduitCarrousel from './Composants/ProduitCarrousel';

import ProduitPaiementDirect from './Composants/ProduitPaiementDirect';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// Items => Ajout Panier
import CartItem from './Composants/CartIcon';
import Cart from './Composants/Cart';
import { useSelector } from "react-redux";
import { RootState } from './Store/store';


const ProduitDetail = () => {
    const { productId } = useParams(); // Obtient l'ID du produit depuis l'URL

    // Recherche du produit correspondant en fonction de productId
    const produit = produitsData.find((p) => p.to === productId);
    const [infosOpen, setInfosOpen] = useState(false);
    const [compositionsOpen, setCompositionsOpen] = useState(false);
    const estUnAlbum = produit?.type === 'album';

    const toggleInfos = () => {
        setInfosOpen(!infosOpen);
    };

    const toggleCompositions = () => {
        setCompositionsOpen(!compositionsOpen);
    };

    // Type pour l'état du panier
    const canIViewCar = useSelector((state: RootState) => state.shoppingCart.isCartVisible);


    return (
        <section className="BoutiqueContainer">
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11436637044"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11436637044');
        `}
                </script>
            </Helmet>
            <section className="AccueilContainer" >
                <CartItem />
                {canIViewCar && <Cart />}
                <div className="BanderauGauche">
          <div className="BanderauGauche">
            <motion.p
              initial={{ y: -200, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 200, opacity: 0 }}
              transition={{
                duration: "0.6",
                delay: 0.2
              }}
              className="BanderauGaucheTexte"><span className="outline-artiste">BOUT</span>IQUE</motion.p>
          </div>
        </div>
                <section className="wrap">
                    {produit && (
                        <section className="produit-detail-container">
                            <ProduitCarrousel />
                            <section className="produit-info">
                                <div className="produit-header">
                                    <div className="produit-titre-Qte">
                                        <h2 className="produit-titre">{produit.Titre}</h2>
                                    </div>
                                    <div className="FlexCentre">
                                        <h5 className="produit-prix">{produit.Prix} €</h5>
                                    </div>
                                    <p className="produit-description">{produit.Description}</p>
                                    <ProduitPaiementDirect />

                                </div>
                                {!estUnAlbum && produit.Détail && (
                                    <div className='CompoInfos'>
                                        <motion.div layout className="faq-item">
                                            <motion.h4 layout onClick={toggleCompositions}>
                                                Composition{" "}
                                                <IoIosArrowDown
                                                    className={`arrow-icon ${compositionsOpen ? "open" : ""}`}
                                                    style={{ transform: compositionsOpen ? "rotate(180deg)" : "" }}
                                                />
                                            </motion.h4>
                                            <AnimatePresence>
                                                {compositionsOpen && (
                                                    <motion.p
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: "auto", opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                        className="produit-details-text compositons"
                                                        key="compositions"
                                                    >
                                                        {produit.Compositions}
                                                    </motion.p>
                                                )}
                                            </AnimatePresence>
                                        </motion.div>
                                        <motion.div layout className="faq-item">
                                            <motion.h4 layout onClick={toggleInfos}>
                                                Infos Complémentaires{" "}
                                                <IoIosArrowDown
                                                    className={`arrow-icon ${infosOpen ? "open" : ""}`}
                                                    style={{ transform: infosOpen ? "rotate(180deg)" : "" }}
                                                />
                                            </motion.h4>
                                            <AnimatePresence>
                                                {infosOpen && (
                                                    <motion.p
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: "auto", opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                        className="produit-details-text compositons"
                                                        key="compositions"
                                                    >
                                                        {produit.Détail.map((Détails) => (
                                                            <li key={Détails.Question}>- {Détails.Question} : {Détails.Réponse}. </li>
                                                        ))}
                                                    </motion.p>
                                                )}
                                            </AnimatePresence>
                                        </motion.div>
                                    </div>
                                )}
                                {estUnAlbum && produit.Tracks && (
                                    <motion.div layout className="faq-item">
                                        <motion.h4 layout onClick={toggleCompositions}>
                                            Tracklist{" "}
                                            <IoIosArrowDown
                                                className={`arrow-icon ${compositionsOpen ? "open" : ""}`}
                                                style={{ transform: compositionsOpen ? "rotate(180deg)" : "" }}
                                            />
                                        </motion.h4>
                                        <AnimatePresence>
                                            {compositionsOpen && (
                                                <motion.div
                                                    initial={{ height: 0, opacity: 0 }}
                                                    animate={{ height: "auto", opacity: 1 }}
                                                    exit={{ height: 0, opacity: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                    className="produit-details-text tracklist"
                                                >
                                                    {produit.Tracks.map((track) => (
                                                        <p key={track.number}>
                                                            {track.number} - {track.title}
                                                        </p>
                                                    ))}
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </motion.div>
                                )}
                            </section>
                        </section>
                    )}


                    <NavLink className='Retour' to='/Boutique'>
                        <button>Retour à la boutique</button>
                    </NavLink>
                </section>
            </section>
        </section>
    );
};

export default ProduitDetail;
