import React from 'react';
import MenuBurger from './MenuBurger';

const Headers: React.FC = () => {
  return (
    <header id="Header">
      <div className='ContainerHeader'>
        <MenuBurger />
        <a className='LOGO-Anime' href="/">
          <div className="PULSAR">
            <div className="PULSAR__container">
              <svg className='LogoHeader' id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                <defs>  <style dangerouslySetInnerHTML={{ __html: `.cls-1{fill:#fff;}` }} /></defs>
                <title>V2</title>
                <g id="V2">
                  <polygon className="cls-1" points="217.87 486.05 217.7 486.38 217.92 486.05 500.11 178.34 754.21 486.05 817.64 486.05 499.94 100.17 217.7 486.38 217.87 486.05" />
                  <polygon className="cls-1" points="782.13 513.95 782.3 513.62 782.08 513.95 499.89 821.66 245.79 513.95 182.36 513.95 500.06 899.83 782.3 513.62 782.13 513.95" /></g>
              </svg>
              <div className="pulse">
                <span style={{ "--i": 0 } as React.CSSProperties}></span>
                <span style={{ "--i": 1 } as React.CSSProperties}></span>
                <span style={{ "--i": 2 } as React.CSSProperties}></span>
              </div>
            </div>
            <div className="PULSAR__name">
              <h1>PULSAR</h1>
            </div>
          </div>
        </a>
      </div>
    </header>
  );
};

export default Headers;
