import React from "react";
import { useDispatch } from "react-redux";
import { shoppingCartActions } from "../Store/shopping-cart-slice";

// Définition du type pour les items du panier
interface CartItemProps {
    item: {
        id: string;
        Titre: string;
        cover: string;
        quantite: number;
        Prix: number;
        Taille: string;
        Couleur: string;
        priceId : string;

    };
}

const CartItem: React.FC<CartItemProps> = ({ item }) => {
    const dispatch = useDispatch();

    const handleRemoveItem = () => {
        dispatch(shoppingCartActions.removeItemFromCart(item.id));
    };

    const handleIncrementButtonClick = () => {
        dispatch(shoppingCartActions.incrementQuantity(item.id));
    };
    const handleDecrementButtonClick = () => {
        dispatch(shoppingCartActions.decrementQuantity(item.id));
    };

    return (
        <div className="cart-item">
            <div className="left-part">
                <div className="cart-item-image">
                    <img src={`${item.cover}`} alt="" />
                </div>
                <div className="item-details">
                    <div className="Titre">
                        <h6>{item.Titre}    <p> {item.Taille} </p>  <p> {item.Couleur} </p>
                        </h6>
                    </div>
                    <p>
                        {item.quantite} x {item.Prix} €
                    </p>
                    <button className="RemoveItem" onClick={handleRemoveItem}>Retirer du panier</button>
                </div>
            </div>
            <div className="right-part">
                <div className="Selector">
                    <span onClick={handleDecrementButtonClick}>-</span>
                    <span>{item.quantite}</span>
                    <span onClick={handleIncrementButtonClick}>+</span>
                </div>
                <div className="Price">{item.Prix * item.quantite} €</div>
            </div>
        </div>
    );
};

export default CartItem;
