import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import React, { useEffect } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import BandeauGauche from "../components/BandeauGauche";



export const CGV = () => {
    //LOCOMOTIVE SCROLL
    useEffect(() => {
        const scrollContainer = document.querySelector('[data-scroll-container]') as HTMLElement;

        if (scrollContainer) {
            const scroll = new LocomotiveScroll({
                el: scrollContainer,
                smooth: true,
            });
            return () => {
                scroll.destroy();
            };
        }
    }, []);
    return (
        <motion.main>
            <Helmet>
                <title>Pulsar inc. - CGV</title>
                <link rel="canonical" href="https://pulsar-inc.fr/CGV" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11436637044"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11436637044');
        `}
                </script>
            </Helmet>
            <section className="AccueilContainer">
                <BandeauGauche
                    TexteOutline='mentions'
                    Texte='légales' />
                <section data-scroll data-scroll-container className="wrap">
                    <div className="CGV-container">
                        <section className="ContainerColonne50">
                            <div className="Colonne50" id="MentionLegal">
                                <h1>Mentions légales</h1>
                                <p>Ce site est celui de :</p>
                                <p>Pulsar inc. 2 RUE DU GUIZAY, 42100 SAINT-ETIENNE </p>
                                <p>SIRET 92350078900014 – APE 90.02Z – SAINT ETIENNE</p>
                                <p>Représenté par Mr Raphael SALAMONE</p>
                                <p>Email : contact@pulsar-inc.fr</p>
                                <p>Téléphone : +33 6 37 17 44 74</p>

                                <p> Conception / réalisation</p>
                                <p>Vincent MARCONNET</p>
                                <p> vincent.marconnet@pulsar-inc.fr</p>

                            </div>
                            <div className="Colonne50" id="CGV">
                                <h1> Conditions générales de ventes (CGV)</h1>
                                <h2>PREAMBULE</h2>
                                <p>Les présentes « Conditions Générales de Vente » s’appliquent aux opérations juridiques portant sur les produits commercialisés auprès de ses clients par Pulsar inc au sein de sa boutique virtuelle.</p>
                                <h2 className="Titre-CGV">Clause n°1 : Objet et champ d'application</h2>
                                <p>
                                    Les présentes conditions générales de vente (CGV) constituent le socle de la négociation commerciale et sont systématiquement adressées ou remises à chaque acheteur pour lui permettre de passer commande.
                                    Les conditions générales de vente décrites ci-après détaillent les droits et obligations de l4association PULSAR INC. et de son client dans le cadre de la vente de l’intégralité des marchandises vendues sur le site www.pulsar-inc.fr.
                                    Toute acceptation du devis/bon de commande en ce compris la clause « Je reconnais avoir pris connaissance et j'accepte les conditions générales de vente ci-annexées » implique l'adhésion sans réserve de l'acheteur aux présentes conditions générales de vente.
                                </p>
                                <h2 className="Titre-CGV">Clause n°2 : Protection des données</h2>
                                <p>L’association PULSAR INC. garantit que les données indiquées par ses clients resteront strictement confidentielles.

                                    Ainsi, les informations clients telles que nom, prénom, adresse de livraison, adresse mail et numéro de téléphone ne pourront en aucun cas être cédées à des tiers.
                                </p>
                                <h2 className="Titre-CGV">Clause n°3 : Prix</h2>
                                <p>
                                    Les prix des marchandises vendues sont ceux en vigueur au jour de la prise de commande. Ils sont libellés en euros et calculés hors taxes. Par voie de conséquence, ils seront majorés des frais de transport et de livraison applicables au jour de la commande, en fonction du produit.
                                    L’association PULSAR INC. s'accorde le droit de modifier ses tarifs à tout moment. Toutefois, elle s'engage à facturer les marchandises commandées aux prix indiqués lors de l'enregistrement de la commande.

                                </p>
                                <h2 className="Titre-CGV">Clause n°4 : Rabais et ristournes</h2>
                                <p>
                                    Les tarifs proposés comprennent les rabais et ristournes que l’association PULSAR INC. serait amenée à octroyer compte tenu de ses résultats ou de la prise en charge par l'acheteur de certaines prestations.
                                </p>
                                <h2 className="Titre-CGV">Clause n°5 : Escompte</h2>
                                <p>Aucun escompte ne sera consenti en cas de paiement anticipé.</p>

                                <h2 className="Titre-CGV">Clause n°6 : Modalités de paiement</h2>
                                <p>
                                    Le règlement des commandes s'effectue :
                                    par carte bancaire ;
                                    Les règlements seront effectués aux conditions suivantes :
                                    Paiement à 30 jours suivant la réception des marchandises

                                </p>
                                <h2 className="Titre-CGV">Clause n°7 : Retard de paiement</h2>
                                <p>
                                    En cas de défaut de paiement total ou partiel des marchandises livrées à l'échéance, l'acheteur doit verser à l’association PULSAR INC. une pénalité de retard égale à trois fois le taux de l'intérêt légal.
                                    Le taux de l'intérêt légal retenu est celui en vigueur au jour de la livraison des marchandises.
                                    A compter du 1er janvier 2015, le taux d'intérêt légal sera révisé tous les 6 mois (Ordonnance n°2014-947 du 20 août 2014).
                                    Cette pénalité est calculée sur le montant TTC de la somme restante due, et court à compter de la date d'échéance du prix sans qu'aucune mise en demeure préalable ne soit nécessaire.
                                    En sus des indemnités de retard, toute somme, y compris l’acompte, non payée à sa date d'exigibilité, produira de plein droit le paiement d’une indemnité forfaitaire de 40 euros due au titre des frais de recouvrement.
                                    Articles 441-10 et D. 441-5 du code de commerce.

                                </p>
                                <h2 className="Titre-CGV">Clause n°8 : Clause résolutoire</h2>
                                <p>
                                    Si dans les quinze jours qui suivent la mise en œuvre de la clause "Retard de paiement", l'acheteur ne s'est pas acquitté des sommes restant dues, la vente sera résolue de plein droit et pourra ouvrir droit à l'allocation de dommages et intérêts au profit de l’association PULSAR INC.
                                </p>
                                <h2 className="Titre-CGV">Clause n°9 : Clause de réserve de propriété</h2>
                                <p>
                                    L’association PULSAR INC. conserve la propriété des biens vendus jusqu'au paiement intégral du prix, en principal et en accessoires. À ce titre, si l'acheteur fait l'objet d'un redressement ou d'une liquidation judiciaire, l’association PULSAR INC. se réserve le droit de revendiquer, dans le cadre de la procédure collective, les marchandises vendues et restées impayées.
                                </p>
                                <h2 className="Titre-CGV">Clause n°10 : Livraison</h2>
                                <p>
                                    La livraison est effectuée :
                                    soit par la remise directe de la marchandise à l'acheteur ;
                                    soit par l'envoi de colis au domicile ou à l’adresse indiquée par l’acheteur ;
                                    soit par la réception du colis en main propre au siège social de l’association, au 2 rue du Guizay, 42000 Saint-Etienne.
                                    Le délai de livraison indiqué lors de l'enregistrement de la commande n'est donné qu'à titre indicatif et n'est aucunement garanti.
                                    Par voie de conséquence, tout retard raisonnable dans la livraison des produits ne pourra pas donner lieu au profit de l'acheteur à :
                                    l'allocation de dommages et intérêts ;
                                    l'annulation de la commande.
                                    Le risque du transport est supporté en totalité par l'acheteur.
                                </p>
                                <h2 className="Titre-CGV">Clause n°11 : Marchandises manquantes ou détériorées</h2>
                                <p>
                                    En cas de marchandises manquantes ou détériorées lors du transport, l'acheteur devra formuler toutes les réserves nécessaires sur le bon de commande à réception desdites marchandises. Ces réserves devront être, en outre, confirmées par écrit dans les cinq jours suivant la livraison, par courrier recommandé avec accusé de réception adressé à la société.
                                    Si le cas exposé suivant les règles ci-dessus sont jugées équivoques et réelles par l’association PULSAR INC., une solution pourra être proposée à l’acheteur :
                                    Le remboursement intégral de la commande ;
                                    Un avoir du montant partiel ou intégral de la commande ;
                                    Un nouvel envoi du produit correspondant à la commande, sous réserve qu’il soit disponible.

                                </p>
                                <h2 className="Titre-CGV">Clause n° 12 : Force majeure</h2>
                                <p>
                                    La responsabilité de l’association PULSAR INC. ne pourra pas être mise en œuvre si la non-exécution ou le retard dans l'exécution de l'une de ses obligations décrites dans les présentes conditions générales de vente découle d'un cas de force majeure. À ce titre, la force majeure s'entend de tout événement extérieur, imprévisible et irrésistible au sens de l'article 1148 du Code civil.
                                </p>
                                <h2 className="Titre-CGV">Clause n° 13 : Tribunal compétent</h2>
                                <p>
                                    Tout litige relatif à l'interprétation et à l'exécution des présentes conditions générales de vente est soumis au droit français.
                                    À défaut de résolution amiable, le litige sera porté devant le Tribunal de commerce de Saint-Etienne.

                                    Fait à Saint-Etienne, le 04/11/2023

                                    (signature du représentant légal la société)
                                </p>
                            </div>
                        </section>
                    </div>
                </section>
            </section>
        </motion.main>
    );
};

export default CGV;

