import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';
import LocomotiveScroll from 'locomotive-scroll';
import EvenementItem from '../components/EvenementItem';
import EvenementModal from '../components/EvenementModal';
import EventSlider from '../components/EventSlider';
import BandeauGauche from '../components/BandeauGauche';
import axios from 'axios';

export type Evenement = {
  id: number;
  NomEven: string;
  Artiste: string;
  Lieu: string;
  dateProp: Date;
  googleMapsLink: string;
  photos: string[];
};

const Evenements: React.FC = () => {
  const [evenementsFuturs, setEvenementsFuturs] = useState<Evenement[]>([]);
  const [evenementsPasses, setEvenementsPasses] = useState<Evenement[]>([]);
  const [isScrollInitialized, setIsScrollInitialized] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEvenement, setSelectedEvenement] = useState<Evenement | null>(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://pulsar-inc.fr:3001/api/events/');
        const events = response.data;

        if (!Array.isArray(events)) {
          throw new Error('La réponse de l\'API n\'est pas un tableau');
        }

        const currentDate = new Date();
        const futurs = events.filter(evenement => new Date(evenement.dateProp) >= currentDate)
          .sort((a, b) => new Date(a.dateProp).getTime() - new Date(b.dateProp).getTime());
        const passes = events.filter(evenement => new Date(evenement.dateProp) < currentDate)
          .sort((a, b) => new Date(b.dateProp).getTime() - new Date(a.dateProp).getTime());

        setEvenementsFuturs(futurs);
        setEvenementsPasses(passes);
      } catch (error) {
        console.error('Erreur lors de la récupération des événements :', error);
      }
    };

    fetchEvents();
  }, []);

  const initializeLocomotiveScroll = async () => {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    const scrollContainer = document.querySelector('[data-scroll-container]') as HTMLElement;

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
      });

      setIsScrollInitialized(true);
    }
  };

  const handleOpenModal = (evenement: Evenement) => {
    setSelectedEvenement(evenement);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvenement(null);
  };

  // Générer un objet JSON-LD pour un événement spécifique
  const generateEventJsonLd = (event: Evenement) => {
    return {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": event.NomEven,
      "startDate": new Date(event.dateProp).toISOString(),
      "location": {
        "@type": "Place",
        "name": event.Lieu,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": event.Lieu,
          "addressLocality": "Saint-Étienne",
          "postalCode": "42000",
          "addressCountry": "FR"
        }
      },
      "image": event.photos.length > 0 ? `https://pulsar-inc.fr${event.photos[0]}` : '',
      "description": `Découvrez les photos de l'événement "${event.NomEven}" organisé par Pulsar Inc.`,
      "performer": {
        "@type": "MusicGroup",
        "name": event.Artiste
      },
    };
  };

  // Générer un tableau JSON-LD pour tous les événements
  const generateAllEventsJsonLd = () => {
    const allEventsJsonLd = evenementsFuturs.map(event => generateEventJsonLd(event));
    return JSON.stringify(allEventsJsonLd);
  };

  return (
    <motion.main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pulsar Inc. - Événements</title>
        <meta name="description" content="Découvrez tous les événements à venir à Saint-Étienne organisés par Pulsar Inc." />
        <meta name="keywords" content="Pulsar, Événements, Saint-Étienne, Concerts, Artistes" />
        <meta name="author" content="Pulsar Inc." />
        <link rel="canonical" href="https://pulsar-inc.fr/Evenements" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="Événements à Saint-Étienne - Pulsar Inc." />
        <meta property="og:description" content="Explorez les événements à Saint-Étienne organisés par Pulsar Inc." />
        <meta property="og:url" content="https://pulsar-inc.fr/Evenements" />
        <meta property="og:image" content="https://pulsar-inc.fr/images/evenement-thumbnail.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="fr_FR" />

        {/* Twitter Card Meta Tags for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Événements à Saint-Étienne - Pulsar Inc." />
        <meta name="twitter:description" content="Explorez les événements à Saint-Étienne organisés par Pulsar Inc." />
        <meta name="twitter:image" content="https://pulsar-inc.fr/images/evenement-thumbnail.jpg" />

        {/* JSON-LD for structured data */}
        <script type="application/ld+json">
          {generateAllEventsJsonLd()}
        </script>
      </Helmet>

      <section className="AccueilContainer">
        <BandeauGauche TexteOutline='événe' Texte='ments' />

        <section className="wrap">
          <main className="Evenements">
            <h1> Événements à la une</h1>
            <section className='Evenement-ALU'>
              <EventSlider events={evenementsFuturs.slice(0, 3)} />
            </section>
            <h2> • Programme complet :</h2>
            <section className='Evenement-PC'>
              <div className="Container-Cartes-Evenement">
                {evenementsFuturs.map(event => (
                  <EvenementItem key={event.id} evenement={event} />
                ))}
              </div>
            </section>
            <h2> • Programme passé :</h2>
            <section className='Evenement-PS'>
              <div className="Container-Cartes-Evenement">
                {evenementsPasses.map(event => (
                  <EvenementItem key={event.id} evenement={event} isPast onOpenModal={handleOpenModal} />
                ))}
              </div>
            </section>
          </main>
        </section>
      </section>
      {isModalOpen && selectedEvenement && (
        <EvenementModal evenement={selectedEvenement} onClose={handleCloseModal} />
      )}
    </motion.main>
  );
};

export default Evenements;