import React, { useEffect } from 'react';
import { Evenement } from '../data/EvenementsData';
import { motion } from 'framer-motion';
import MyImage from './reactLazyLoading';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface ModalProps {
    evenement: Evenement;
    onClose: () => void;
}

const EvenementModal: React.FC<ModalProps> = ({ evenement, onClose }) => {
    // Utilisez useEffect pour ajouter et retirer la classe modal-open
    useEffect(() => {
        // Ajouter la classe modal-open au body et à html
        document.body.classList.add('modal-open');
        document.documentElement.classList.add('modal-open');

        // Retirer la classe modal-open au démontage du composant
        return () => {
            document.body.classList.remove('modal-open');
            document.documentElement.classList.remove('modal-open');
        };
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="Evenement-Modal"
            onClick={onClose}
        >
            <motion.div
                layout
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.3 }}
                className="Evenement-Modal-content"
                onClick={(e) => e.stopPropagation()}
            >
                <button className="Fermer-modal" onClick={onClose}>
                    <span></span>
                </button>
                <h2>{evenement.NomEven}</h2>
                <div className='Evenement-Modal-infos'>
                    <p>Date: {new Date(evenement.dateProp).toLocaleDateString()}</p>
                    <p>Heure: {new Date(evenement.dateProp).toLocaleTimeString()}</p>
                    <p>Lieu: <a href={evenement.googleMapsLink} target="_blank" rel="noopener noreferrer">{evenement.Lieu}</a></p>
                    <p>Artiste: {evenement.Artiste}</p>
                </div>
                <div className="photos">
                    <MyImage
                        images={evenement.photos} height="auto" width="100%"
                    />
                </div>
            </motion.div>
        </motion.div>
    );
};

export default EvenementModal;
