import React, { useEffect, useState } from 'react';
import { FaCartShopping } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { shoppingCartActions } from '../Store/shopping-cart-slice';
import { RootState } from '../Store/store';
import { motion, useAnimation } from 'framer-motion';

const CartIcon: React.FC = () => {
    const dispatch = useDispatch();
    // Utiliser reduce pour calculer la quantité totale
    const cartLength = useSelector((state: RootState) => state.shoppingCart.items.reduce((total, item) => total + item.quantite, 0));
    const controls = useAnimation();
    const [previousLength, setPreviousLength] = useState(cartLength);

    useEffect(() => {
        if (cartLength > previousLength) {
            controls.start({
                scale: 1.3,
                transition: { type: 'spring', stiffness: 300, duration: 0.2 }
            }).then(() => {
                controls.start({ scale: 1 });
            });
        }
        setPreviousLength(cartLength);
    }, [cartLength, controls, previousLength]);

    const handleClick = () => {
        dispatch(shoppingCartActions.toggleCartView());
    };

    return (
        <div className='Cart' onClick={handleClick}>
            <motion.div animate={controls}>
                <FaCartShopping className="cart-icon" />
            </motion.div>
            <div className='cart-icon-number'>
                <p>({cartLength})</p>
            </div>
        </div>
    );
};

export default CartIcon;
