import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// PAGES
import Evenements from "./screens/Evenements";
import { Boutique } from "./screens/Boutique";
import Contact from "./screens/Contact";
import ProduitDetail from "./screens/Boutique/ProduitDetailsPages";
import CGV from "./screens/CGV";
const LazyHome = React.lazy(() => import('./screens/Home'))
const LazyMitchy = React.lazy(() => import('./screens/Mitchy'))

const AnimRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path="/"element={<LazyHome/>}/>
        <Route path="/Mitchy" element={<React.Suspense fallback='Loading...' ><LazyMitchy /></React.Suspense>} />
        <Route path="/Evenements" element={<Evenements />} />
        <Route path="/Boutique" element={<Boutique />} />
        <Route path="/Boutique/:productId" element={<ProduitDetail />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/cgv" element={<CGV />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
