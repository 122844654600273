// shopping-cart-slice.tsx
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Type pour un élément du panier
interface CartItem {
    id: string;
    Titre: string;
    cover: string;
    quantite: number;
    Prix: number;
    Taille : string;
    Couleur : string;
    priceId : string;
}

// Type pour l'état du panier
interface ShoppingCartState {
    items: CartItem[];
    isCartVisible: boolean;
}

// État initial du panier
const initialState: ShoppingCartState = {
    items: [],
    isCartVisible: false,
};

const shoppingCartSlice = createSlice({
    name: "shopping-cart",
    initialState,
    reducers: {
        // Basculer la visibilité du panier
        toggleCartView(state) {
            state.isCartVisible = !state.isCartVisible;
        },
 
        // Ajouter un article au panier
        addItemToCart(state, action: PayloadAction<CartItem>) {
            const newProduct = action.payload;
            const existingProductItem = state.items.find(item => item.id === newProduct.id);

            if (!existingProductItem) {
                // Si le produit n'existe pas dans le panier, l'ajouter
                state.items.push({ ...newProduct, quantite: 1 });
            } else {
                // Sinon, incrémenter la quantité
                existingProductItem.quantite++;
            }
        },

        // Supprimer un article du panier
        removeItemFromCart(state, action: PayloadAction<string>) {
            state.items = state.items.filter(item => item.id !== action.payload);
        },

        // Augmenter la quantité d'un article dans le panier
        incrementQuantity(state, action: PayloadAction<string>) {
            const productIndex = state.items.findIndex(item => item.id === action.payload);
            if (productIndex !== -1) {
                state.items[productIndex].quantite++;
            }
        },

        // Diminuer la quantité d'un article dans le panier
        decrementQuantity(state, action: PayloadAction<string>) {
            const productIndex = state.items.findIndex(item => item.id === action.payload);
            if (productIndex !== -1 && state.items[productIndex].quantite > 1) {
                state.items[productIndex].quantite--;
            } else {
                {
                    state.items = state.items.filter(item => item.id !== action.payload);
                }
            }
        },
    },
});

export const shoppingCartActions = shoppingCartSlice.actions;

export default shoppingCartSlice;
