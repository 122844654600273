import React, { useState, useEffect } from 'react';

import './App.css';
import './locomotive-scroll.css'
import '../src/styles/Components/ModalCart.css';
import '../src/styles/Components/BtnBox.css';
import '../src/styles/Components/VSlide4.css';
import '../src/styles/Components/VSlide5.css';
import '../src/styles/Components/VSlide6.css';
import '../src/styles/Components/Header.css';
import '../src/styles/Components/Footer.css';
import '../src/styles/Components/MenuBurger.css';
import '../src/styles/Components/EventDetailsPages.css';
import '../src/styles/Components/Abonnements.css';
import '../src/styles/Components/CarrouselAuto.css';
import '../src/styles/Components/ScrollToTopButton.css';
import '../src/styles/Components/WelcomeModal.css'


import '../src/styles/Pages/Accueil.css';
import '../src/styles/Pages/Evenements.css';
import '../src/styles/Pages/Artistes.css';
import '../src/styles/Pages/Boutique.css';
import '../src/styles/Pages/ProduitDetails.css';
import '../src/styles/Pages/Produits.css';
import '../src/styles/Pages/CGV.css'


import WelcomeModal from './components/WelcomeModal';
import Footer from './components/Footer'
import Headers from "./components/Headers"

import AnimRoutes from './AnimRoutes';

export default function App() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('firstVisit')) {
      setShowModal(true);
      localStorage.setItem('firstVisit', '1');
    }
  }, []);

  const closeModal = () => setShowModal(false);

  return (
    <div className='App' >
      <WelcomeModal showModal={showModal} closeModal={closeModal} />
      <Headers />
      <AnimRoutes />
      <Footer />
    </div>

  );
}


