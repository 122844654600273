import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LienPaiement, produits, produitsData } from '../../../data/produitsData';

//Ajout au Panier
import { useDispatch } from 'react-redux';
import { shoppingCartActions } from '../Store/shopping-cart-slice';
import CartItem from './CratItem';



interface CartItem {
    id: string;
    Titre: string;
    cover: string;
    quantite: number;
    Prix: number;
    Taille: string;
    Couleur: string;
    priceId: string;
}
const ProduitPaiementDirect: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();
    const produit = produitsData.find((p: produits) => p.to === productId);

    const [taille, setTaille] = useState<string>('');
    const [couleur, setCouleur] = useState<string>('');
    const [messageErreur, setMessageErreur] = useState<string>('');

    //Ajouter au panier
    const dispatch = useDispatch();

    if (!produit) {
        return <p>Produit non trouvé</p>;
    }

    const obtenirLienPaiement = (): string => {
        if (produit.besoinChoixCouleur && couleur) {
            const lienCouleur = couleur === "Blanc" ? produit.LienPaiementCouleur0 : produit.LienPaiementCouleur1;
            if (lienCouleur && lienCouleur.length > 0) {
                return lienCouleur[0].lienDirect;
            }
        }
        if (produit.besoinChoixTaille && taille) {
            const lienTaille = produit[`LienPaiementTaille${taille}` as keyof typeof produit] as LienPaiement[];
            if (lienTaille && lienTaille.length > 0) {
                return lienTaille[0].lienDirect;
            }
        }
        if (produit.LienPaiementUnique && produit.LienPaiementUnique.length > 0) {
            return produit.LienPaiementUnique[0].lienDirect;
        }
        return '';
    };

    const lienPaiement = obtenirLienPaiement();

    const handleClick = (): void => {
        if (produit.besoinChoixCouleur && !couleur) {
            setMessageErreur("Veuillez sélectionner une couleur");
            return;
        }

        if (produit.besoinChoixTaille && !taille) {
            setMessageErreur("Veuillez sélectionner une taille");
            return;
        }

        setMessageErreur('');
        window.open(lienPaiement, '_blank');
    };

    // Gère l'ajout d'un article au panierconst handleAddItemToCart = () => {
    const handleAddItemToCart = () => {
        if (!produit) {
            return;
        }

        if (produit.besoinChoixTaille && !taille) {
            setMessageErreur("Veuillez sélectionner une taille");
            return;
        }

        if (produit.besoinChoixCouleur && !couleur) {
            setMessageErreur("Veuillez sélectionner une couleur");
            return;
        }

        setMessageErreur('');

        let priceId = '';
        if (produit.type === 'album') {
            // Utiliser le lien de paiement pour les albums
            priceId = produit.LienPaiementUnique && produit.LienPaiementUnique.length > 0 ? produit.LienPaiementUnique[0].priceId : '';
        } else {
            if (produit.besoinChoixTaille) {
                const tailleKey = `LienPaiementTaille${taille}` as keyof typeof produit;
                const lienPaiementTaille = produit[tailleKey] as LienPaiement[];
                if (lienPaiementTaille && lienPaiementTaille.length > 0) {
                    priceId = lienPaiementTaille[0].priceId;
                }
            }

            if (produit.besoinChoixCouleur) {
                const couleurIndex = couleur === "Blanc" ? 0 : 1;
                const lienCouleurKey = `LienPaiementCouleur${couleurIndex}` as keyof typeof produit;
                const lienPaiementCouleur = produit[lienCouleurKey] as LienPaiement[];
                if (lienPaiementCouleur && lienPaiementCouleur.length > 0) {
                    priceId = lienPaiementCouleur[0].priceId;
                }
            }
        }

        const uniqueProductId = `${produit.id}-${taille}-${couleur}`;

        const itemToCart: CartItem = {
            id: uniqueProductId,
            Titre: produit.Titre,
            cover: produit.ImagesSupplementaires[0],
            quantite: 1,
            Prix: produit.Prix,
            Taille: taille,
            Couleur: couleur,
            priceId: priceId
        };

        dispatch(shoppingCartActions.addItemToCart(itemToCart));

        // Affichage de l'objet dans la console
        console.log("Article ajouté au panier :", itemToCart);
    };


    return (
        <div className="Choix-produit">
            {produit.besoinChoixTaille && (
                <div className="taille-select">
                    <label htmlFor="taille">Taille :</label>
                    <select id="taille" name="taille" value={taille} onChange={(e) => setTaille(e.target.value)}>
                        <option value="">Choisir une taille</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                    </select>
                </div>
            )}

            {produit.besoinChoixCouleur && (
                <div className="couleur-select">
                    <label htmlFor="couleur">Couleur :</label>
                    <select id="couleur" name="couleur" value={couleur} onChange={(e) => setCouleur(e.target.value)}>
                        <option value="">Choisir une couleur</option>
                        {produit.OptionsCouleur?.map((couleurOption, index) => (
                            <option key={index} value={couleurOption}>{couleurOption}</option>
                        ))}
                    </select>
                </div>
            )}

            <div className='disponibilite'>
                <div className={produit.enStock ? "dispo" : "indispo"}></div>
                <p>{produit.enStock ? "En stock" : "En rupture de stock"}</p>
            </div>


            <div className='ButtonAchat'>
                {produit.enStock && (
                    <>
                        <button onClick={handleClick} className="Panier">{produit.TexteBouton}</button>
                        <button onClick={handleAddItemToCart} className="Panier">Ajouter au panier</button>
                    </>
                )}
                {messageErreur && <p style={{ color: 'red' }}>{messageErreur}</p>}
            </div>
        </div>
    );
};

export default ProduitPaiementDirect;
