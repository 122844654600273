import { useRef, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import emailjs from '@emailjs/browser';
import { FaCheck } from "react-icons/fa"
import { motion } from 'framer-motion';



import { BiSolidContact, BiMap } from 'react-icons/bi'
import { FaPhoneAlt } from 'react-icons/fa'
import VSlide4 from '../components/Vslide4';

import BandeauGauche from '../components/BandeauGauche';


function VSlide5() {

  const form = useRef<HTMLFormElement>(null);

  const [notification, setNotification] = useState('');

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification('');
      }, 10000); // Disparition après 10 secondes

      return () => clearTimeout(timer);
    }
  }, [notification]);

  //Envoie email.js
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Utiliser l'opérateur d'affirmation non-null
    emailjs.sendForm('service_tzq86xk', 'template_lm9rx0p', form.current!, 'hck3l50x61JUqVPzc')
      .then((result) => {
        console.log(result.text);
        setNotification('Message envoyé avec succès');
        form.current!.reset();
      }, (error) => {
        console.log(error.text);
        setNotification('Une erreur est survenue lors de l\'envoi du message');
      });
  };

  return (
    <motion.main>
      <Helmet>
        <title>Pulsar inc. - Contact</title>
        <link rel="canonical" href="https://pulsar-inc.fr/Contact" />
        <meta name="description" content="Contactez Pulsar Inc. à Saint-Étienne pour des renseignements sur
         nos événements musicaux, nos artistes ou toute autre demande. Notre équipe est prête à vous assister
          et répondre à vos questions."></meta>
      </Helmet>
      <section className="AccueilContainer">
        <BandeauGauche TexteOutline={'Con'} Texte={'tact'}/>

        <section className="wrap">
          <motion.section className='v-slide5' id='contact'>
            <h1>Contactez-nous</h1>
            <div className="Contact-box" id='Contact-box'>
              <div className='Colonne50'>
                <img src="images/Logo/Nouveau LOGO/V1-8.png" alt="" />
                <div className='Horizontale'>
                  <div className='ColonneCentre'>
                    <BiSolidContact style={{ fontSize: '2.5em', color: "#EEEEEE" }} />
                    <p>contact@pulsar-inc.fr</p>
                  </div>
                  {/* <div className='ColonneCentre'>
                    <FaPhoneAlt style={{ fontSize: '2.5em', color: "#EEEEEE" }} />
                    <p>(+33) 6 19 28 83 86</p>
                  </div> */}
                  {/* <div className='ColonneCentre'>
                <BiMap style={{ fontSize: '2.5em', color: "#EEEEEE" }} />
                <p>2 RUE DU GUIZAY, 42100 SAINT-ETIENNE</p>
              </div> */}
                </div>
              </div>

              <div className='Colonne50'>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="user-box">
                    <label>Nom</label>
                    <input type="text" name="user_name" placeholder='Votre nom' required /></div>

                  <div className="user-box">
                    <label>Email</label>
                    <input type="email" name="user_email" placeholder='Votre email' required /></div>
                  <div className="user-box">
                    <label>Message</label> <br />
                    <textarea name="message" placeholder='Ecrivez votre message ici' required /></div>
                  <input type="submit" value="Envoyer" />
                </form>
              </div>
              {notification && (
                <div className="Notification">
                  <p> <FaCheck style={{ color: 'green', marginRight: '5px' }} />{notification}</p>
                </div>
              )}
            </div>

          </motion.section>
          <VSlide4 />
        </section>
      </section>
    </motion.main>
  );
};

export default VSlide5;