import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';
import CartItem from "./CratItem";
import { RootState } from "../Store/store";
import { motion } from 'framer-motion';
import { shoppingCartActions } from "../Store/shopping-cart-slice";

// Initialisez Stripe.js avec votre clé publique
const stripePromise = loadStripe('pk_live_51O8fUtCik0FZhKhAfypFXdzey87eEBFXA5qUkzimN1fWi9hj3DpyNk444OxOpHUJJohwQMheN6BZ8GKafI89OOWA00fM6CV8YZ');

export default function Cart() {
    const dispatch = useDispatch();
    const items = useSelector((state: RootState) => state.shoppingCart.items);

    // Gestionnaire de clic pour fermer la modal
    const handleClickBackdrop = () => {
        dispatch(shoppingCartActions.toggleCartView());
    };
    
    // Gestionnaire de clic pour empêcher la fermeture de la modal lors du clic sur son contenu
    const handleClickModal = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    // Calcul du prix total
    const prixTotal = useMemo(() => {
        return items.reduce((total, item) => {
            return total + (item.Prix * item.quantite);
        }, 0);
    }, [items]);

    useEffect(() => {
        const simplifiedItems = items.map(item => ({
            priceId: item.priceId,
            quantite: item.quantite
        }));
        console.log("Contenu simplifié du panier :", JSON.stringify({ items: simplifiedItems }, null, 2));
    }, [items]);

    const payerAvecStripe = async () => {
        const itemsAvecPriceId = items.filter(item => item.priceId);

        if (itemsAvecPriceId.length === 0) {
            alert("Votre panier contient des articles sans prix. Veuillez les retirer avant de continuer.");
            return;
        }

        try {
            console.log('Envoi de la requête à /api/stripe/create-checkout-session avec les éléments:', itemsAvecPriceId);
            const response = await fetch('https://pulsar-inc.fr:3080/api/stripe/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    items: itemsAvecPriceId.map(item => ({
                        priceId: item.priceId,
                        quantite: item.quantite
                    }))
                }),
            });

            console.log('Réponse reçue:', response);
            if (!response.ok) {
                console.error('Réponse non OK:', response.statusText);
                throw new Error('La réponse du réseau n\'était pas ok');
            }

            const responseData = await response.json();
            console.log('Données de réponse:', responseData);

            if (responseData.sessionId) {
                const stripe = await stripePromise;
                if (!stripe) {
                    console.error("Stripe n'a pas été initialisé correctement.");
                    return;
                }

                const result = await stripe.redirectToCheckout({ sessionId: responseData.sessionId });
                if (result.error) {
                    alert(result.error.message);
                }
            } else {
                console.error('Échec de la création de la session de paiement', responseData);
                alert('Erreur lors de la création de la session de paiement. Veuillez réessayer.');
            }
        } catch (error) {
            console.error('Erreur lors de la communication avec le serveur:', error);
            alert('Une erreur est survenue lors de la communication avec le serveur.');
        }
    };

    let content = <div className="Container-Carte-Items"> <p>Votre panier est vide</p></div>;
    if (items.length > 0) {
        content = (
            <div className="Container-Carte-Items">
                {items.map(item => (
                    <CartItem key={item.id} item={item} />
                ))}
            </div>
        );
    }

    return (
        <div className="BackdropModalCart" onClick={handleClickBackdrop}>
            <motion.div
                className="ModalCart"
                initial={{ x: '100vw', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '100vw', opacity: 0 }}
                transition={{ duration: 0.3 }}
                onClick={handleClickModal} // Empêcher la propagation du clic au backdrop
            >
                <h2>Votre panier comporte ({items.length}) éléments</h2>
                {content}
                <div className="CalculPrixTotal">
                    <p>Total : {prixTotal.toFixed(2)} €</p>
                </div>
                <button onClick={payerAvecStripe} className="Panier">Valider le panier</button>
            </motion.div>
        </div>
    );
}
