// store.tsx
import { configureStore } from "@reduxjs/toolkit";
import shoppingCartSlice from "./shopping-cart-slice";

const store = configureStore({
    reducer: {
        shoppingCart: shoppingCartSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
