import React from 'react';
import { motion } from 'framer-motion';
import EasterEgg from './EasterEgg';

interface BandeauGaucheProps {
    TexteOutline: string;
    Texte: string;
}

const BandeauGauche: React.FC<BandeauGaucheProps> = ({ Texte, TexteOutline }) => {
    return (
        <div className="BanderauGauche">
            <motion.p
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 100, opacity: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="BanderauGaucheTexte"
            >
                <span className="outline-artiste">{TexteOutline}</span>{Texte}
            </motion.p>
            <EasterEgg/>
        </div>
    );
};

export default BandeauGauche;
